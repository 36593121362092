import React from 'react'
import { play_icon } from '../../imports'
import './hero.css'

const smallText = [
  {
    title: 'Max Power',
    description: ' 350 HP'
  },
  {
    title: 'Max Speed',
    description: '360 MPH'
  },
  {
    title: 'Acceleration',
    description: '5.5 sec'
  },
  {
    title: 'Engine Layout',
    description: 'V6'
  }
]

const Hero = () => {
  return (
    <section className={`hero-container`}>

      <div className={`hero-video`}>
        <video autoPlay loop muted playsInline className={`video-hor`}>
          <source src="https://res.cloudinary.com/ddtbiyfpj/video/upload/v1712467729/Fastlane%20Valet/Videos/FastLane_commercial_low_dev3yv.mp4" type="video/mp4" />

        </video>
      </div>

      <div className={`hero-front-text`}>
        <div className={`hero-header`}>
          <h1>
            A bold statement, elegantly delivered
          </h1>
        </div>

        <div className={`hero-button`}>
          <div className={`play-button`}>
            <img src={play_icon} alt='play button' />
          </div>
          <p>Learn More</p>
        </div>

        <div className={`hero-text-stack`}>
          {smallText.map((text, id) => (
            <div className={`text-wrapper`} key={id}>
              <div className={`text-title`}>
                <p>
                  {text.title}
                </p>
              </div>
              <div className={`text-desc`}>
                <p>
                  {text.description}
                </p>
              </div>
            </div>
          ))}


        </div>
      </div>
    </section>
  )
}

export default Hero