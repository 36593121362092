import React from 'react';
import { useNavigate } from 'react-router-dom';
import { detail_1, detail_2, detail_3, detail_4 } from '../../imports'
import './gallery.css'

const detailData = [
  {
    title: 'Pressure Wash',
    pic: detail_1,
  },
  {
    title: 'Scrapping',
    pic: detail_2,
  },
  {
    title: 'In-Vaccuum',
    pic: detail_3,
  },
  {
    title: 'Polishing',
    pic: detail_4,
  },
]

const Gallery = () => {
  const Navigate = useNavigate();
  const navigateToWork = () => {
    Navigate('/gallery');
  };

  return (
    <section className={`gallery-container`}>
      <div className={`gallery-title`}>
        <h1>
          Our high-end previous work:
        </h1>
      </div>

      <div className={`gallery-pic-bg`}>
        {
          detailData.map((data, id) =>
            <div className={`gallery-pic-container`} key={id}>
              <img src={data.pic} alt={data.title} />
              <p> {data.title}</p>
            </div>
          )
        }
      </div>

      <div className={`gallery-button`}>
          <button onClick={navigateToWork}>
            View All Work
          </button>
      </div>

    </section>
  )
}

export default Gallery