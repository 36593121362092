import React from 'react';
import { useNavigate } from 'react-router-dom';
import './contact.css'

const Contact = () => {
    const Navigate = useNavigate();
  const navigateToContact = () => {
    Navigate('/contact');
  };

  return (
    <section className={`contact-container`}>
      <div  className={`contact-header`}>
        <h1>
        Join us as we embark on a journey to design a better future, one visionary project at a time.
        </h1>
      </div>

      <div  className={`contact-button`}>
        <button onClick={navigateToContact}>
          Contact
        </button>
      </div>
    </section>
  )
}

export default Contact