import React, { useEffect } from 'react'
import './contactPage.css'



const ContactPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="contact-page-container">

      <div className={`contact-page-text`}>
        <div className={`contact-page-header`}>
          <p>Contact us</p>
          <h1>Start reaching your digital goals</h1>
        </div>
        <div className={`contact-info`}>
          <div className={`contact-info-chat`}>

            <h1>
              Chat to us
            </h1>
            <p>
              Our friendly team is here to help
            </p>
            <p>
              team@fastlane.com
            </p>
          </div>
          <div className={`contact-info-call`}>
            <h1>
              Call us
            </h1>
            <p>
            (+1) 689 177 0959
            </p>

          </div>
        </div>
      </div>

      <div className="contact-form-container">

        <div className={`contact-form`}>
          <div className="contact-booking">
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/kanahcode?hide_gdpr_banner=1&background_color=000000&text_color=ffffff&primary_color=f5f6fc"

            />
          </div>
        </div>

      </div>

    </div>
  )
}

export default ContactPage