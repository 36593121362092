import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Hero, Services, Gallery, Contact, Footer } from './components';
import { ServicesPage, ContactPage, GalleryPage } from './pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <div className='App'>
          <Navbar />
          <Routes>
            <Route path="/" element={<>
                                      <Hero />
                                      <Services />
                                      <Gallery />
                                      <Contact />
            </>}
            />
          <Route path={`/services`} element={<ServicesPage />} />
          <Route path={`/gallery`} element={<GalleryPage />} />
          <Route path={`/contact`} element={<ContactPage />} />
          </Routes>
          <Footer />
        </div>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
