import React, { useState } from 'react';
import { car_1, car_2, car_3, car_4, car_5, car_6, car_7, car_8, car_9 } from '../../imports';
import './galleryPage.css'

const images = [car_1, car_2, car_3, car_4, car_5, car_6, car_7, car_8, car_9]

const GalleryPage = () => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className={`gallery-page-container`}>
      <div className={`gallery-page-header`}>
        <p>Gallery</p>
        <h1>Checkout our previous work</h1>
      </div>
      <div className="image-preview">
        <img src={selectedImage} alt="Selected" className="selected-image" />
      </div>
      <div className="image-list">
        {images.map((image, index) => (
          <button key={index} className="image-button" onClick={() => setSelectedImage(image)}>
            <img src={image} alt={`Car Detailing ${index + 1}`} />
          </button>
        ))}
      </div>
    </div>
  )
}

export default GalleryPage