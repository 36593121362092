import React from 'react'
import './servicesPage.css'

const ServicesPage = () => {
  return (
    <div className={`services-page-container`}>
        servicesPage
    </div>
  )
} 

export default ServicesPage