import React from 'react'
import { car_1, car_2, car_7 } from '../../imports'
import './services.css'

const Services = () => {
  return (
    <section className={`services-container`}>
      <div className='service-header'>
        <div className={`services-smaller-title`}>
          <p>Why Fastlane Valet</p>
          </div>
          <div className={`services-title`}>

            <h1> Our high-end Detailing Services</h1>
        </div>
      </div>
      <div className={`services-content-container`}>
        <div className={`services-left-content`}>
          <p>
            Modern luxury home design means utilizing current technologies like AI, Virtual Reality, and 3D drone scanning of your home, allowing you to make changes on the spot, streamlining and expediting the design, planning, and building process.
            Modern luxury home design means utilizing current technologies like AI, Virtual Reality, and 3D drone scanning of your home, allowing you to make changes on the spot, streamlining and expediting the design, planning, and building process.
          </p>
        </div>

        <div className={`services-right-content`}>
          <img src={car_7} alt='mercedes car' />
        </div>
      </div>


    </section>
  )
}

export default Services